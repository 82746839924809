button {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 5px 5px !important;
  height: 100% !important;
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  letter-spacing: 2px !important;
  border-radius: 4px !important;
  text-transform: uppercase !important;
  outline: 0 !important;
  overflow: hidden !important;
  z-index: 1 !important;
  cursor: pointer !important;
  transition: 0.08s ease-in !important;
  -o-transition: 0.08s ease-in !important;
  -ms-transition: 0.08s ease-in !important;
  -moz-transition: 0.08s ease-in !important;
  -webkit-transition: 0.08s ease-in !important;
}
.fill {
  background: none !important;
}

.fill:before {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: 100% !important;
  z-index: -1 !important;
  -webkit-transition: top 0.04s ease-in !important;
}

.fill:hover:before {
  top: 0 !important;
}
.fill:after {
  content: '' !important;
  background: #cacfca !important;
  border-color: #cacfca !important;
  display: block !important;
  position: absolute !important;
  padding-top: 500% !important;
  padding-left: 350% !important;
  margin-left: -20px !important;
  margin-top: -220% !important;
  opacity: 0 !important;
  transition: all 0.1s !important;
}

.fill:active:after {
  opacity: 0.2 !important;
  transition: 0s !important;
}

.filled {
  position: relative !important;
  -webkit-transition-duration: 0.4s !important; /* Safari */
  transition-duration: 0.4s !important;
  text-decoration: none !important;
  overflow: hidden !important;
  cursor: pointer !important;
}

.filled:after {
  content: '' !important;
  background: #cacfca !important;
  border-color: #cacfca !important;
  display: block !important;
  position: absolute !important;
  padding-top: 500% !important;
  padding-left: 350% !important;
  margin-left: -20px !important;
  margin-top: -220% !important;
  opacity: 0 !important;
  transition: all 0.1s !important;
}

.filled:active:after {
  opacity: 0.2 !important;
  transition: 0s !important;
}

.disabled {
  background: gray;
  border: 2px gray solid;
  color: whitesmoke;
}