.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.PanelTitle {
  font-size: 1.25rem;
  margin: 1rem;
}

.InputTitle {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: black;
}

.MiddlePanel {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 62rem;
  height: 44rem;
}

.SendNotificationButton {
  width: 10em;
  height: 2.5em;
}

.Inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
  flex-grow: 1;
  flex-basis: 0;
}

.DevicePreviewWrapper {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.BottomPanel {
  display: flex;
  flex-direction: row;
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
}

.DevicePreviewTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.DevicePreview {
  margin-top: 3rem;
}

.ArabicDevicePreview {
  margin-top: 6rem;
}

.LoadingLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LoadingLogo {
  width: 20rem;
}

.ErrorContainer {
  height: 25px;
}
