.FormWrappingDiv {
  width: 21em;
  display: flex;
  justify-content: center;
  margin-right: auto;
  padding-top: 1em;
  padding-bottom: 1.5em;
  margin-left: auto;
  margin-top: 3em;
  border-radius: 0.5em;
}

.LogoWrappingDiv {
  margin-right: auto;
  margin-left: auto;
  padding-top: 4em;
  display: flex;
  justify-content: center;
}

.Logo {
  height: auto;
  width: 18em;
}

.InputWrappingDiv {
  margin-top: 1.5em;
}

.Input {
  width: 15em;
}

.ButtonWrappingDiv {
  margin-top: 2em;
  height: 2.5em;
}

.ForgotPassword {
  font-size: 0.6rem;
  margin-bottom: 0.2em;
}

.ForgotPasswordDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
  cursor: pointer;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.BackgroundDiv {
  height: 100vh;
}
