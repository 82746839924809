.TextWrapper {
    display: flex;
    align-items: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  .container {
      /* width: 6.5em; */
    height: 3em;
    display: inline;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .container:hover {
    opacity: 0.5;
  }
  
  .Icon {
    font-size: 1.2rem;
    margin-top: 0.1em;
    margin-left: 0.75em;
  }
  
  .Text {
    font-size: 1rem;
    font-weight: bold;
  }
  