.SideNavBarWrappingDiv {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 4.5em;
}

.logoWrappingDiv {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 1em;
  width: 4.5em;
  height: 3.6em;
}

.IconImg {
  max-width: 80%;
  max-height: 80%;
}

.icon {
  border-width: 0 !important;
  width: 2.2em !important;
  height: 2.2em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.3em;
  font-size: 1.5em !important;
  margin-bottom: 0.75em;
}

.iconsWrappingDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Link {
  height: 2.88rem;
  margin-bottom: 0.75rem;
}
