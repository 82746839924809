.TextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: black !important;
  background-color: white !important;
}

.TextField {
  all: unset;
  flex-grow: 1;
}

.TextInputWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.Button {
  flex-shrink: 0;
  height: 2em;
  width: 6em;
  font-size: 1rem;
  margin-right: 1em;
}

.InputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1em;
}
.Select {
  /* flex-grow: 1; */
  width: 15%;
}
.RangePickerButton {
  flex-grow: 1;
  width: 12%;
  font-weight: 200;
  font-size: 1em;
  background-color: Transparent;
  opacity: 0.7;
  border: none;
  text-align: start;
}
.RangePickerButton:hover {
  background-color: inherit;
  opacity: 1.1;
}
.RangePicker {
  height: inherit;
  background-color: inherit;
  width: 8%;
  flex-grow: 1;
}

.FilterIcon {
  margin-left: 1em;
  margin-right: 0.5em;
  font-size: 1.25rem;
}

.container {
  width: 6.5em;
  height: 3em;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 0%;
}
.navi {
  width: 100%;
  height: 3em;
  position: absolute;
  top: 0;
  left: 0;
}
.infoi {
  display: flex;
  align-items: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.accordion:focus-visible {
  background-color: white;
  outline: none;
}
