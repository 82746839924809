.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.AdminsContainer {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  width: 70em;
  height: 45em;
}

.HeaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.AdminTextContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
}

.RoleTextContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
}

.HeaderButtonContainer {
  height: 100%;
}

.HeaderTitles {
  margin-block-start: 0;
  margin-block-end: 0;
  bottom: 0em;
  padding-left: 1em;
  font-size: 1.25rem;
}

.AddButton {
  width: 8em;
  height: 3em;
  align-self: flex-end;
}

.AdminList {
  height: 39em;
  padding-top: 1em;
}

.LoadingIndicator {
  text-align: center;
  width: 4rem;
}

.LoadingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
