.SummaryLeftContainer {
  display: flex;
  padding: 1em;
  flex-direction: column;
  width: 60%;
}

.NameContainer {
  margin-bottom: 0.25em;
}

.emailContainer {
  margin-bottom: 0.25em;
}

.SummaryRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.DetailsContainer {
  display: flex;
  width: 100%;
}

.DetailsLeftContainer {
  display: flex;
  padding: 1em;
  border-radius: 1em;
  flex-direction: column;
  width: 70%;
}

.DetailsRightContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
}

.StatusButton {
  width: 12em;
}

.StatusContainer {
  width: 6em;
  padding: 0.25em;
  border-radius: 1em;
  text-align: center;
}

.OutsideContainer {
  align-content: center;
  margin: 0.5em;
}
