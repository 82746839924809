.Container {
  display: flex;
  width: 100%;
  height: 3.5em;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
  justify-content: space-between;
}

.NumberOfUsersText {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.3125rem;
  margin-inline-start: 1em;
}

.SortText {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.3125rem;
  margin-inline-end: 1em;
}

.SortIcon {
  margin-inline-start: 0.5em;
}
