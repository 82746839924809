@font-face {
  font-family: 'PTSans';
  src: local('PTSans'),
    url(../../Assets/Fonts/PT_Sans/PTSans-Regular.ttf) format('truetype');
}

.text {
  font-family: 'PTSans';
  margin: 0;
}
