.Container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
  padding-bottom: 0.25rem;
  padding-top: 1.25rem;
}

.Filter {
  padding: 1rem;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.VouchersContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TitleHeadersContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;
}

.WrapperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.Container {
  display: flex;
  flex-direction: row;
}

.Container2 {
  background-color: white;
  width: 97.5%;
  height: 100%;
  align-self: center;
}

.HeaderButtonContainer {
  height: 100%;
  justify-content: flex-end;
  padding: 1.5rem;
}

.CreateButton {
  width: 8em;
  height: 3em;
  align-self: flex-end;
}

.TopContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FieldText {
  display: flex;
  flex-grow: 0.2;
  flex-basis: 0;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Extra{
  display: flex;
  flex-grow: 0.5;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;
}