.TitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Title {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.ExitIcon {
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  margin-right: 6rem;
}
