.container {
  display: flex;
  align-self: center;
}

.text {
  align-self: center;
  font-size: 1rem;
  margin-left: 0.5rem;
}
.icon {
  align-self: center;
  width: 1rem !important;
}
