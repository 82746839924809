.NoUsersFoundText {
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
}

.Container {
  width: 100%;
}

.NoUsersFoundContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.NoUsersFoundImage {
  width: 3em;
  height: 3em;
  display: flex;
  align-self: center;
  margin-top: 2em;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}
