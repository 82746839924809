.WrapperContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FieldContainer {
  display: flex;
  flex-grow: 0.65;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.FieldText {
  font-size: 0.9rem;
}

.PenIcon {
  font-size: 1rem;
  align-self: center;
  margin-right: 1em;
  cursor: pointer;
  flex-grow: 0.2;
  flex-basis: 0;
}

.PenContainer {
  align-self: center;
  flex-grow: 0.33;
  flex-basis: 0;
}

.EditableContainer {
  display: flex;
  flex-grow: 0.8;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 400;
}

.redeemContainer {
  display: flex;
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

.AccordionDetailsContainer {
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  flex: 1;
}
