.Transaction {
  margin: 0.5em;
  width: auto;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.LoadingIndicatorWrapper {
  display: flex;
  justify-content: center;
  padding-top: 6em;
}
.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}
.NoSessions {
  margin-top: 0.5rem;
}
