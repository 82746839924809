.WrapperContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 0.5rem;
  width: max-content;
  flex: 1;
  flex-basis: 0;
}

.ExpiryContainer {
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex: 1;
  justify-content: center;
}

.FieldText {
  font-size: 1.1rem;
}

.DateFieldText {
  font-size: 14.5;
}

.ProgressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-basis: 0;
}
