.Container {
  display: flex;
  width: 100%;
  height: 4em;
  align-items: center;
  flex-direction: row;
}

.Icon {
  align-self: center;
  text-align: center;
  padding: 0.1em;
  margin-left: -0.25em;
  flex: 0.2;
}
.Station {
  text-align: start;
  padding-left: 0.7em;
  font-size: 1rem;
  flex: 0.75;
}

.Amount {
  font-size: 0.92rem;
  text-align: center !important;
  margin-top: 0rem;
  flex: 1;
  flex: 1;
}
.Date {
  font-size: 1rem;
  text-align: center;
  margin-top: 0rem;
  flex: 0.75;
}

.ChargingTimeText {
  font-weight: bold;
  font-size: medium;
}
.EmptyDiv {
  flex-grow: 3;
}
.ChargingTime {
  margin-top: 0rem;
  align-self: center;
}

.AccordionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ChargingDetails {
  display: flex;
  flex-direction: row;
}

.UidContainer {
  display: flex;
  justify-content: flex-end;
}

.ReverseButton {
  align-self: center;
  text-align: center;
  height: 2em;
  max-width: 6rem;
  font-size: 1rem;
  display: flex;
}

.TopDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.RightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.BottomDetailsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
