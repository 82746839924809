.FormWrappingDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.5em;
  padding-bottom: 1em;
}

.FieldsWrapper {
  display: flex;
  justify-content: center;
}

.InputWrappingDiv {
  margin-top: 1.5em;
  transition: margin-top 0.75s;
}

.Input {
  width: 18em;
}

.ButtonWrappingDiv {
  margin-top: 1.5em;
  height: 2.5em;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}

.ShowContainer {
  opacity: 1;
  height: 115px;
  transition: opacity 0.5s, height 0.75s;
}

.HideContainer {
  opacity: 0;
  height: 0px;
  transition: opacity 0.5s, height 0.75s;
}

.InputWrappingDivHidden {
  margin-top: 0rem;
  transition: margin-top 0.75s;
}
