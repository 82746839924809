.Title {
  font-size: 1.35rem;
}

.HeaderBarWrappingDiv {
  width: 100%;
  height: 3.75em;
  display: flex;
  flex-direction: row;
}

.TitleWrappingDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1em;
  flex-grow: 1;
  height: 3.5em;
}

.AvatarWrappingDiv {
  display: flex;
  align-self: center;
  width: unset;
  background-color: transparent;
  border-width: 0;
  margin-right: 2.5em;
  max-width: 5em !important;
  cursor: pointer;
}

.LogoutButton {
  height: 2em;
  width: 5em;
  font-size: 1rem;
  justify-content: center;
  display: flex;
}

.UsernameStyling {
  display: flex;
  justify-content: center;
}
