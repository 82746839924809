.Container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
}
.Filter {
  padding: 1em;
  max-width: 100%;
  max-height: 100%;
}
.TranactionsFilter {
  padding-bottom: 1.5em;
}
.UsersContainer {
  flex-grow: 5;
  display: flex;
  flex-direction: row;
  padding-right: 1em;
  padding-bottom: 0.5em;
  height: 100%;
}
.UsersList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 1em;
  padding-left: 1em;
}
.UsersListWrapper {
  height: 100%;
  flex-grow: 1;
}
.UserContainer {
  flex-basis: 0;
  flex-grow: 5.5;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
}
.UserDataContainer {
  flex-grow: 6;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.UserInfoContainer {
  display: flex;
  flex-direction: row;
  padding-top: 2em;
}
.UserAvatar {
  flex-grow: 0.5;
  margin-left: 1.5em;
}
.UserInfo {
  flex-grow: 15;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.UserInfoRightContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  padding-right: 1.5em;
}
.CreatedAt {
  text-align: center;
  align-self: flex-end;
  margin-bottom: 1.5em;
  align-items: center;
  display: flex;
}

.InfoText {
  align-items: center;
  display: flex;
  padding-bottom: 0.7em;
}
.InfoTextPhone {
  align-items: center;
  display: flex;
  padding-bottom: 0.7em;
  width: fit-content;
}
.PhoneIcon {
  cursor: pointer;
}
.ChargingTokensContainer {
  padding: 1.5em;
  margin-top: 1em;
  padding-bottom: 0em;
}
.TransactionsContainer {
  margin: 1.5em;
  margin-bottom: -1em;
  display: flex;
  flex-basis: 0;
  flex-shrink: 1;
  flex-direction: column;
  max-height: initial;
}
.TranactionsFilter {
  flex-shrink: 1;
  max-width: 100%;
}
.Transactions {
  margin-right: 1em;
  margin-left: 1em;
  max-height: 60vh;
}

.UserActionsContainer {
  flex-grow: 1;
  margin-top: 1em;
  margin-bottom: 1em;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UserActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 0;
  flex-grow: 10;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;
  text-align: center;
}

.ActionButtons {
  flex-grow: 6;
}
.ButtonWrapper {
  padding-bottom: 1em;
}
.FormContainer {
  padding-bottom: 1em;
  width: inherit;
  max-width: inherit;
}
.Wallet {
  display: flex;
  text-align: center;
  align-self: flex-end;
  align-items: center;
}

.DeactivateButton {
  margin-bottom: 1em;
}
.HeaderText {
  font-weight: bold;
  font-size: 1.333rem;
  line-height: 1.9375rem;
  margin-bottom: 0.7em;
}

.ActionsTitle {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding-top: 0.5em;
  margin-bottom: 1.5em;
}
.ButtonClass {
  height: 3.5em;
}

.TextField {
  outline: 1px;
}
.CurrencyText {
  font-size: 1.5rem;
  padding-left: 0.5em;
}

.LoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.EditPhoneModalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.LoadingIndicator {
  padding-left: 0.4em;
  width: fit-content;
}

.ButtonPhoneEditModal {
  font-size: 0.75rem;
  padding-right: 1.3em;
  padding-bottom: 0.5em;

  width: 9em;
  height: 3.5em;
}

.PaymentSessions {
  margin-top: 1em;
  margin-right: 1em;
  margin-left: 1em;
  max-height: 100%;
}

.PointsContainer {
  max-height: 25rem;
}

.WalletContainer {
  display: flex;
  flex-direction: column;
}

.PointsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UsersWrapper {
  height: 100%;
}
