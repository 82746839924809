.ListItemSummary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ListItemDetails {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.CancelButton {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 8em;
  height: 3em;
}

.UpdateButton {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 8em;
  height: 3em;
}

.AvatarNameEmail {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
}

.AvatarIcon {
  align-self: center;
}

.Name {
  font-size: 1rem;

  /* padding: 0rem; */
  margin: 0.25rem;
}

.Email {
  /* padding: 0rem; */
  margin: 0.25rem;
}

.Role {
  font-size: 1rem;
  align-content: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-basis: 0;
}

.PenIcon {
  font-size: 1rem;
  align-self: center;
  margin-right: 1em;
}

.DeleteIcon {
  font-size: 1.2rem;
  align-self: center;
  margin-right: 1em;
}

.DropDown {
  align-self: center;
  flex-grow: 1;
  flex-basis: 0;
}

.Container {
  align-content: center;
  margin: 0.5em;
}

.NameEmail {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  justify-content: center;
}

.LoadingIndicator {
  text-align: center;
  width: 3rem;
}
