.WrapperContainer {
  display: flex;
  flex-direction: row;
}

.FieldContainer {
  display: flex;
  justify-content: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-grow: 1;
  flex-basis: 0;
}

.IDContainer {
  display: flex;
  justify-content: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-grow: 1.75;
  flex-basis: 0;
  margin-left: 1rem;
}

.FieldText {
  font-size: 0.9rem;
}

.DetailsContainer {
  padding-top: 0.5rem;
  margin-left: 1rem;
}
