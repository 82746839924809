.Container {
    border-radius: 5rem;
    margin-top: 6;
  }

 .LabelStyle {
    padding: 15;
    font-weight: bold;
  }

  .FillerStyle {
    height: 100%;
    border-radius: inherit;
    text-align: right;
  }
  