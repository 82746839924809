.WrapperContainer {
  display: flex;
  flex-direction: row;
}

.FieldContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.FieldText {
  font-size: 0.9rem;
}
