.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.SpaceContainer {
  height: 1rem;
}
