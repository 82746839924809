.Points {
  margin: 0.5em;
}
.WaypointContainer {
  display: flex;
  justify-content: center;
}

.LoadingIndicatorWrapper {
  display: flex;
  justify-content: center;
  padding-top: 6em;
}

.NoPointsFoundContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.Container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.PointsItem {
  padding-bottom: 0.75rem;
}
