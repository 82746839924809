.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}

.ModalTitle {
  font-weight: bold;
}
