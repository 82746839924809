.FormWrappingDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.5em;
  padding-bottom: 1em;
}

.FieldsWrapper {
  display: flex;
  justify-content: center;
}

.InputWrappingDiv {
  margin-top: 1.5em;
}

.Input {
  width: 18em;
}

.ButtonWrappingDiv {
  margin-top: 2em;
  height: 2.5em;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.LoadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
