.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ContactUsContainer {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  width: 70em;
  height: 50em;
}

.ContactUsTextContainer {
  flex-grow: 1;
  margin-top: 1em;
  display: flex;
  flex-direction: column-reverse;
}

.HeaderTitles {
  margin-block-start: 0;
  margin-block-end: 0;
  bottom: 0em;
  padding-left: 1em;
  font-size: 1.25rem;
}

.ContactUsFormsList {
  height: 39em;
}

.LoadingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.Filter {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.25em;
  max-width: 100%;
  max-height: 100%;
}
