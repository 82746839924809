.listItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4em;
  border-radius: 5px;
  align-content: flex-start;
}

.MarginContainer {
  width: 100%;
  align-content: flex-start;
  display: flex;
  align-items: center;
}

.iconCard {
  align-self: center;
  font-size: 1.25rem;
  text-align: center;
  flex: 0.2;
  margin-left: -0.25em;
  padding: 0.2em;
}

.transactionId {
  align-self: center;
  font-size: 1rem;
  margin-left: 0.8em;
  text-align: start;
  flex: 0.75;
}

.transactionAmount {
  align-self: center;
  text-align: center;
  padding-right: 2em;
  font-size: 1rem;
  flex: 1.2;
}
.transactionDate {
  align-self: center;
  font-size: 1rem;
  text-align: center;
  flex: 0.475;
  padding-right: 1rem;
}

.refundButton {
  align-self: center;
  text-align: center;
  height: 2em;
  min-width: 5.4em;
  font-size: 1rem;
  justify-content: center;
  display: flex;
}

.refundButtonWrapper {
  justify-content: flex-end;
  display: flex;
  flex: 0.01;
}

.refundButtonEmptyContainer {
  width: 4em;
  flex-basis: 0;
}
