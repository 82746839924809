.Container {
  width: inherit;
  height: 5em;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1em;
  cursor: pointer;
}

.UserDetailsContainer {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex-grow: 5;
}
.Avatar {
  margin-right: 1em;
}
.LastSeenItem {
  flex-grow: 1;
  align-self: flex-start;
  text-align: end;
  padding-right: 1em;
  padding-top: 0.7em;
}
.UserNameText {
  font-size: 1.125rem;
  padding-bottom: 0.5em;
}

.LastSeenText {
  font-size: 0.75rem;
  line-height: 0.5rem;
}

.Phonetext {
  font-size: 0.75rem;
  margin-top: -0.5em;
}
