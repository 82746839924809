.iconCard {
  justify-content: center;
  align-self: center;
  height: 1.5em;
  font-size: 1.25rem;
}

.iconPhone {
  justify-content: center;
  align-self: center;
  width: 22.5px;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
}

.listItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3em;
  border-radius: 5px;
  align-content: flex-start;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.tokenId {
  align-self: center;
  font-size: 1rem;
  flex-basis: 0;
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 1rem;
}
.visualNumber {
  align-self: center;
  font-size: 1rem;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}
.visualNumberText {
  margin-left: 0.5rem;
}

.tokenDate {
  align-self: center;
  margin: 0;
  font-size: 1rem;
  flex-grow: 0.5;
  flex-basis: 0;
}

.toggle {
  align-self: center;
  color: '#000000';
}

.loadingIndicator {
  text-align: center;
  width: 4rem;
}
.tokenStatus {
  align-self: center;
  text-align: center;
  height: 2em;
  min-width: 5.4em;

  font-size: 1rem;
  display: flex;
}
.tokenStatusText {
  text-align: center;
  align-self: center;
  font-size: 1rem;
  text-transform: uppercase;
}
.deactivateWrapper {
  flex-grow: 0.5;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
}
.deactivateContainer {
  width: 7.5rem;
  align-self: center;
}
